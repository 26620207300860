
<template>
  <div class="gallery">
    <div v-if="gallery != null">
      <div v-for="item in gallery.galleries" :key="item.name">
        <router-link :to="{ name: 'GalleryDetail', params: { id: item.id } }" class="gallery-link" tag="section">
          <h2>{{ item.name }}</h2>
          <p>{{ item.text }}</p>
          <simplebar data-simplebar-auto-hide="true">
          <div style="display: inline-flex; width: 100%">
            <div v-for="image in item.images" :key="image" style="flex: 1">
              <img
                v-lazy="image"
                style="max-height: 231px; padding-right: 2px"
              />
            </div>
          </div>
           </simplebar>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

const axios = require("axios").default;

export default {
  name: "Gallery",
  components: {
    simplebar,
  },
  data() {
    return {
      gallery: null,
    };
  },
  mounted() {
    let $vm = this;

    axios
      .get("/data/gallery/gallery.json")
      .then(function (response) {
        $vm.gallery = response.data;
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {

      });
  },
  methods: {
    loadData(data) {
      this.gallery = data;
    },
  },
};
</script>
<template>
  <div class="gallery">
    <div v-if="gallery">
      <h2>{{ gallery.name }}</h2>
      <div class="gallery-box">
        <div v-for="item in gallery.images" :key="item">
          <img
            v-img:group
            v-bind:src="item"
            v-lazy="item"
            class="gallery-img"
          />
        </div>
      </div>
    </div>
    <div v-else>Načítání...</div>
  </div>
</template>

<script>
const axios = require("axios").default;

export default {
  props: ["id"],
  name: "GalleryDetail",
  components: {},
  data() {
    return {
      gallery: null,
      galleryId: "",
    };
  },
  created() {
    try {
      this.galleryId = this.$route.params.id;
    } catch {
      console.log("param null");
    }
  },
  mounted() {
    let $vm = this;

    axios
      .get("/data/gallery/" + $vm.galleryId + "/gallery.json")
      .then(function (response) {
        $vm.gallery = response.data;
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {});
  },
  methods: {
    loadData(data) {
      this.gallery = data;
    },
  },
};
</script>